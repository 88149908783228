import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import Loading from "./Components/Loading";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Work from "./Pages/Work";
import Legal from "./Pages/Legal";
import MobileNav from "./Components/MobileNav";
import WorkDetail from "./Pages/WorkDetail";
import ShareImg from "./images/share.jpg";

import "./App.css";

import styled, { createGlobalStyle } from "styled-components";

var contentful = require("contentful");

var client = contentful.createClient({
  space: "5z7e65pkqg6q",
  accessToken: "h_YNTcSbWyaP1aMqQE-5gP6ClTozLRARfY-rO7rEBnQ",
});

const GlobalStyle = createGlobalStyle`
body{
  max-width: 100vw;
}
  a.button {
    border-radius: 200px;
    padding: 8px 32px;
    background: transparent;
    text-decoration: none;
    border: 1px solid black;
    font-size: 30px;
    font-family: "Object Sans Thin", sans-serif;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 600px){
      font-size: 20px;
    }
  }
  a.button.inverted {
    border: 1px solid white;
    color: white;
  }
  .ghost {
    border: none;
    padding: 0px;
  }
  .collapsed {
    width: 72px;
    height: 100vh;
    box-sizing: border-box;
    position: sticky;
    top: 0px;
    overflow: hidden;
  }
`;

const PageWrap = styled.div`
  display: flex;
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workItems: [],
      workPage: [],
      loaded: false,
    };
    ReactGA.initialize("UA-184848344-1");
  }

  async componentDidMount() {
    const homeContent = await client.getEntries({
      content_type: "homepage",
    });
    const updates = await client.getEntries({
      content_type: "update",
      order: "-sys.createdAt",
      limit: 3,
    });
    const workItems = await client.getEntries({
      content_type: "work",
      order: "fields.order",
    });
    const workPage = await client.getEntries({
      content_type: "workPage",
    });
    const aboutContent = await client.getEntries({
      content_type: "about",
    });
    const team = await client.getEntries({
      content_type: "teamMember",
      order: "fields.order",
    });
    const legal = await client.getEntries({
      content_type: "legalPage",
    });
    this.setState({
      workItems: workItems.items,
      workPage: workPage.items[0],
      homeContent: homeContent.items[0],
      updates: updates.items,
      aboutContent: aboutContent.items[0],
      team: team.items,
      legal: legal.items[0],
      loaded: true,
    });
  }

  render() {
    if (this.state.loaded) {
      return (
        <Router>
          <GlobalStyle />
          <Switch>
            <Route
              exact
              path="/"
              render={({ match }) => (
                <PageWrap>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <meta property="og:title" content="editaudio" />
                    <meta property="og:site_name" content="editaudio" />
                    <meta property="og:url" content="https://editaud.io" />
                    <meta
                      property="og:description"
                      content={this.state.homeContent.fields.headline}
                    />
                    <meta property="og:type" content="website" />
                    <title>
                      editaudio – {this.state.homeContent.fields.headline}
                    </title>
                  </Helmet>
                  <MobileNav active="home" background="var(--orange)" />
                  <Home
                    selected={true}
                    client={client}
                    homeContent={this.state.homeContent}
                    updates={this.state.updates}
                    ReactGA={ReactGA}
                  />
                  <Work selected={false} client={client} ReactGA={ReactGA} />
                  <About selected={false} client={client} ReactGA={ReactGA} />
                </PageWrap>
              )}
            />
            <Route
              exact
              path="/work"
              render={({ match }) => (
                <PageWrap>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>editaudio – Our Work</title>
                    <meta charSet="utf-8" />
                    <meta property="og:title" content="editaudio – Our Work" />
                    <meta property="og:site_name" content="editaudio" />
                    <meta property="og:url" content="https://editaud.io/work" />
                    <meta property="og:type" content="website" />
                  </Helmet>
                  <MobileNav active="work" background="var(--blue)" />
                  <Home selected={false} client={client} ReactGA={ReactGA} />
                  <Work
                    selected={true}
                    detail={false}
                    workItems={this.state.workItems}
                    workPage={this.state.workPage}
                    ReactGA={ReactGA}
                  />
                  <About selected={false} client={client} ReactGA={ReactGA} />
                </PageWrap>
              )}
            />
            <Route
              path="/work/:slug"
              render={({ match }) => (
                <PageWrap>
                  <MobileNav active="work" background="var(--blue)" />
                  <Home selected={false} client={client} ReactGA={ReactGA} />
                  <WorkDetail
                    selected={true}
                    client={client}
                    match={match}
                    workItems={this.state.workItems}
                    ReactGA={ReactGA}
                  />
                  <About selected={false} client={client} ReactGA={ReactGA} />
                </PageWrap>
              )}
            />
            <Route
              exact
              path="/about"
              render={({ match }) => (
                <PageWrap>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>editaudio – About Us</title>
                    <meta property="og:title" content="editaudio – About Us" />
                    <meta property="og:site_name" content="editaudio" />
                    <meta
                      property="og:url"
                      content="https://editaud.io/about"
                    />
                    <meta property="og:type" content="website" />
                  </Helmet>
                  <MobileNav active="about" background="var(--green)" />
                  <Home selected={false} client={client} ReactGA={ReactGA} />
                  <Work selected={false} client={client} ReactGA={ReactGA} />
                  <About
                    selected={true}
                    client={client}
                    aboutContent={this.state.aboutContent}
                    team={this.state.team}
                    ReactGA={ReactGA}
                  />
                </PageWrap>
              )}
            />
            <Route
              exact
              path="/legal"
              render={({ match }) => (
                <PageWrap>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <meta property="og:title" content="editaudio" />
                    <meta property="og:site_name" content="editaudio" />
                    <meta
                      property="og:url"
                      content="https://editaud.io/legal"
                    />
                    <meta
                      property="og:description"
                      content="editadio | legal"
                    />
                    <meta property="og:type" content="website" />
                    <title>editaudio – legal</title>
                  </Helmet>
                  <Legal legal={this.state.legal} />
                </PageWrap>
              )}
            />
          </Switch>
          <Footer />
        </Router>
      );
    } else {
      return <Loading />;
    }
  }
}

export default App;
