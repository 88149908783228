import React, { Component } from "react";
import styled from "styled-components";
import Alert from "./Alert";
import email from "../images/email.svg";

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  span {
    font-size: 48px;
    line-height: 48px;
    font-family: "Nichrome", sans-serif;
    color: white;
    @media (max-width: 1040px) {
      order: 1;
      font-size: 32px !important;
      line-height: 32px !important;
    }
  }
`;

const BottomRow = styled.div`
  width: 100%;
  div {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 16px;
    padding: 30px;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 200px;
    &:hover {
      cursor: pointer;
    }
    img {
      width: 100%;
    }
    @media (max-width: 600px) {
      padding: 16px;
      margin-top: 0px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: var(--black);
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: hidden;

  @media (max-width: 1040px) {
    padding: 16px;
    ${TopRow} {
      flex-direction: column-reverse;
      span {
        margin-bottom: 16px;
      }
      a {
        margin-bottom: 8px;
      }
    }
  }
`;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alerted: false,
    };
  }
  triggerAlert() {
    this.setState(
      {
        alerted: true,
      },
      () => setTimeout(() => this.setState({ alerted: false }), 3000)
    );
  }

  copyToClipboard = () => {
    const el = "hello@editaud.io";
    var textArea = document.createElement("textarea");
    textArea.value = el;
    textArea.style.cssText =
      "width: 0px !important; height: 0px !important; opacity: 0; position: absolute; top: -20px; left: -20px;";
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.blur();
    if (!this.state.alerted) {
      this.triggerAlert();
    }
  };

  render() {
    return (
      <Wrapper>
        <TopRow>
          <a
            className="button inverted"
            href="https://twitter.com/editaud_io"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            className="button inverted"
            href="https://www.facebook.com/editaud.io1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          <span>Let's Connect</span>
          <a
            className="button inverted"
            href="https://www.instagram.com/editaud.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            className="button inverted"
            href="https://www.linkedin.com/company/editaudio"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </TopRow>
        <BottomRow>
          <div onClick={() => this.copyToClipboard()}>
            <img src={email} alt="asset of hello@editaud.io email address" />
          </div>
        </BottomRow>
        <Alert
          text="Email address copied to clipboard"
          active={this.state.alerted}
        />
      </Wrapper>
    );
  }
}

export default Footer;
