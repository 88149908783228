import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import ShareImg from "../images/share.jpg";

const Wrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  background: var(--blue);
  padding-top: ${(props) => (props.collapsed ? "0px" : "40px")};
  padding-bottom: 40px;
  border-right: 2px solid var(--black);
  @media (max-width: 1188px) {
    display: ${(props) => (props.collapsed ? "none" : "block")};
    padding-top: 100px;
    border: none;
  }
  &:hover {
    cursor: ${(props) => (props.collapsed ? "pointer" : "default")};
    background: ${(props) =>
      props.collapsed ? "var(--darker-blue)" : "var(--blue)"};
  }
`;

const Heading = styled.div`
  font-family: "Nichrome", sans-serif;
  color: var(--text);
  font-size: 80px;
  line-height: 80px;
  display: inline-flex;
  margin-top: 24px;
  @media (max-width: 780px) {
    font-size: 40px;
    line-height: 48px;
    display: flex;
    flex-direction: column;
  }
`;

const Heading2 = styled.div`
  font-family: "Nichrome", sans-serif;
  font-size: 64px;
  line-height: 80px;
  margin-top: 64px;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const Description = styled.div`
  line-height: 48px;
  font-size: 40px;
  font-family: "Object Sans Thin", sans-serif;
  margin-top: 24px;
  max-width: 1000px;
  @media (max-width: 780px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const Cover = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 18px;
  margin-right: 20px;
  @media (max-width: 780px) {
    margin-bottom: 16px;
  }
`;

const Slideshow = styled.div`
  width: calc(100vw - 148px);
  height: 450px;
  padding: 24px 0;
  overflow-x: scroll;
  overflow-y: hidden;
  @media (max-width: 1188px) {
    width: 100%;
    height: 300px;
  }
`;

const SlideshowWrap = styled.div`
  padding: 0 24px;
  display: inline-flex;
  box-sizing: border-box;
  img {
    margin-right: 20px;
    width: auto;
    height: 450px;
    border-radius: 65px;
    @media (max-width: 1188px) {
      height: 300px;
    }
  }
  img:last-child {
    margin: 0;
  }
  @media (max-width: 1188px) {
    padding: 0 16px;
  }
`;

const WorkItem = styled.a`
  box-sizing: border-box;
  height: 340px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  padding: 24px 16px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  color: var(--black);
  text-decoration: none;
  &:nth-child(3n + 3) {
    border-right: none;
  }
  &:hover {
    cursor: pointer;
    background: var(--darker-blue);
  }
  @media (max-width: 950px) {
    border-right: none;
  }
`;

const WorkWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc((100vw - 146px) / 3), 1fr)
  );
  grid-auto-rows: auto;
  border-top: 2px solid black;
  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }
`;

const WorkItemTitle = styled.div`
  font-size: 48px;
  line-height: 48px;
  font-family: "Nichrome", sans-serif;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const WorkItemBottom = styled.div``;

const Services = styled.div`
  width: 100%;
  padding: 24px 0;
  overflow-y: hidden;
`;

const Service = styled.div`
  border-radius: 200px;
  margin: 0 16px 16px 0;
  display: inline-block;
  padding: 8px 24px;
  background: black;
  font-size: 30px;
  font-family: "Object Sans Thin", sans-serif;
  color: var(--blue);
  @media (max-width: 780px) {
    font-size: 20px;
    line-height: 24px;
    padding: 8px;
  }
`;

const Button = styled(Link)`
  border-radius: 200px;
  padding: 8px 32px;
  background: transparent;
  border: 1px solid black;
  font-size: 30px;
  text-decoration: none;
  color: black;
  font-family: "Object Sans Thin", sans-serif;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const Pad = styled.div`
  padding: 0 24px;
  @media (max-width: 600px) {
    padding: 0 16px;
  }
`;

class WorkDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: this.props.workItems.filter(
        (item) => item.fields.slug === this.props.match.params.slug
      )[0],
      filtered: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.selected) {
      this.props.ReactGA.pageview("/work/" + this.props.match.params.slug);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected === false && this.props.selected === true) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return (
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>editaudio – {this.state.current.fields.name}</title>
          <meta
            property="og:title"
            content={"editaudio – " + this.state.current.fields.name}
          />
          <meta property="og:site_name" content="editaudio" />
          <meta
            property="og:url"
            content={
              "https://editaud.io/work/" + this.state.current.fields.slug
            }
          />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={ShareImg} />
        </Helmet>
        <Pad>
          <Button to={"/work"}>Back to Work</Button>
          <br />
          <Heading>
            <Cover src={this.state.current.fields.coverArt.fields.file.url} />
            {this.state.current.fields.name}
          </Heading>
        </Pad>
        {this.state.current.fields.images && (
          <Slideshow>
            <SlideshowWrap>
              {this.state.current.fields.images.map((image) => (
                <img
                  key={image.fields.file.url}
                  src={`${image.fields.file.url}?fm=jpg&fl=progressive`}
                />
              ))}
            </SlideshowWrap>
          </Slideshow>
        )}
        {this.state.current.fields.description && (
          <Pad>
            <Description>{this.state.current.fields.description}</Description>
          </Pad>
        )}
        {this.state.current.fields.featuredEpisodes &&
          this.state.current.fields.featuredEpisodes.length >= 3 && (
            <div>
              <Pad>
                <Heading2>Featured Episodes</Heading2>
              </Pad>
              <WorkWrap>
                {this.state.current.fields.featuredEpisodes.map((item) => (
                  <WorkItem
                    key={item.fields.title}
                    href={item.fields.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <WorkItemBottom>
                      <WorkItemTitle>{item.fields.title}</WorkItemTitle>
                    </WorkItemBottom>
                  </WorkItem>
                ))}
              </WorkWrap>
            </div>
          )}
        <Pad>
          {this.state.current.fields.services && (
            <div>
              <Heading2>What We Did</Heading2>
              <Services>
                {this.state.current.fields.services.map((item) => (
                  <Service key={item.fields.serviceName}>
                    {item.fields.serviceName}
                  </Service>
                ))}
              </Services>
            </div>
          )}
        </Pad>
      </Wrapper>
    );
  }
}

export default WorkDetail;
