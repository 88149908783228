import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
  display: none;
  @media (max-width: 1188px) {
    display: block;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

const Heading = styled.div`
  font-family: "Nichrome", sans-serif;
  color: var(--text);
  font-size: 40px;
  line-height: 40px;
  writing-mode: vertical-rl;
  @media (max-width: 780px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const Logo = styled(Link)`
  font-family: "Nichrome Black", sans-serif;
  font-size: 56px;
  line-height: 80px;
  white-space: nowrap;
  color: ${(props) => (props.open ? "white" : "black")};
  font-size: 32px !important;
  line-height: 32px !important;
  text-decoration: none;
`;

const Panel = styled.div`
  width: 100vw;
  height: 100%;
  min-height: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background: black;
  border-radius: 18px;
  box-sizing: border-box;
`;

const Home = styled(Link)`
  background: var(--orange);
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 16px;
  text-decoration: none;
`;
const About = styled(Link)`
  background: var(--green);
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 16px;
  text-decoration: none;
`;
const Work = styled(Link)`
  background: var(--blue);
  flex: 1;
  border-right: 2px solid black;
  border-left: 2px solid black;
  display: flex;
  justify-content: center;
  padding-top: 16px;
  text-decoration: none;
`;
const Nav = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

const Button = styled.button`
  border-radius: 200px;
  padding: 8px 32px;
  background: transparent;
  border: 1px solid black;
  font-size: 30px;
  font-family: "Object Sans Thin", sans-serif;
`;

const GhostButton = styled(Button)`
  border: none;
  border-radius: 0;
  padding: 0 8px;
`;

class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    if (this.state.open) {
      return (
        <Panel>
          <Header open={true}>
            <Logo
              open={true}
              to="/"
              onClick={() => {
                this.setState({ open: false });
                document.body.className = " ";
              }}
            >
              editaudio
            </Logo>
            <GhostButton
              onClick={() => {
                this.setState({ open: false });
                document.body.className = " ";
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="2.92773"
                  y="0.100586"
                  width="24"
                  height="4"
                  rx="2"
                  transform="rotate(45 2.92773 0.100586)"
                  fill="white"
                />
                <rect
                  x="0.0996094"
                  y="17.071"
                  width="24"
                  height="4"
                  rx="2"
                  transform="rotate(-45 0.0996094 17.071)"
                  fill="white"
                />
              </svg>
            </GhostButton>
          </Header>
          <Nav>
            <Home
              to="/"
              onClick={() => {
                this.setState({ open: false });
                document.body.className = " ";
              }}
            >
              <Heading>Home</Heading>
            </Home>
            <Work
              to="/work"
              onClick={() => {
                this.setState({ open: false });
                document.body.className = " ";
              }}
            >
              <Heading>Our Work</Heading>
            </Work>
            <About
              to="/about"
              onClick={() => {
                this.setState({ open: false });
                document.body.className = " ";
              }}
            >
              <Heading>About Us</Heading>
            </About>
          </Nav>
          <Footer />
        </Panel>
      );
    } else {
      return (
        <Wrapper>
          <Header open={false}>
            <Logo open={false} to="/">
              editaudio
            </Logo>
            <GhostButton
              className="ghost"
              onClick={() => {
                this.setState({ open: true });
                document.body.className = "panelOpen";
              }}
            >
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="4" rx="2" fill="black" />
                <rect y="7" width="24" height="4" rx="2" fill="black" />
                <rect y="14" width="24" height="4" rx="2" fill="black" />
              </svg>
            </GhostButton>
          </Header>
        </Wrapper>
      );
    }
  }
}

export default MobileNav;
