import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
var legalContent;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
`;

const Container = styled.div`
  background: white;
  color: black;
  max-width: 1000px;
  padding: 20px;
  border-radius: 18px;
`;

class Legal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legal: [],
      loaded: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let legal = documentToReactComponents(this.props.legal.fields.body);
    this.setState({
      loaded: true,
      legal: legal,
    });
  }

  render() {
    if (this.state.loaded) {
      return (
        <Wrapper>
          <Container>{this.state.legal}</Container>
        </Wrapper>
      );
    } else {
      return <div />;
    }
  }
}

export default Legal;
