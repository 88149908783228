import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Ticker from "react-ticker";

const Logo = styled.div`
  font-family: "Nichrome Black", sans-serif;
  font-size: 56px;
  line-height: 80px;
  white-space: nowrap;
  color: var(--text);
  writing-mode: ${(props) =>
    props.sideways ? "vertical-rl" : "horizontal-tb"};

  @media (max-width: 1188px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  background: var(--orange);
  border-right: 2px solid var(--black);
  display: ${(props) => (props.collapsed ? "flex" : "block")};
  justify-content: center;
  padding-top: ${(props) => (props.collapsed ? "24px" : "none")};
  box-sizing: border-box;
  @media (max-width: 1188px) {
    display: ${(props) => (props.collapsed ? "none" : "block")};
    padding-top: 100px;
    border-right: none;
  }
  &:hover {
    cursor: ${(props) => (props.collapsed ? "pointer" : "default")};
    background: ${(props) =>
      props.collapsed ? "var(--darker-orange)" : "var(--orange)"};
  }
`;

const HeaderLeft = styled.div`
  box-sizing: border-box;
  padding: 32px 0 32px 32px;
  height: 100%;
  max-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  @media (max-width: 780px) {
    padding: 16px;
  }
`;

const HeaderRight = styled.div`
  width: 100%;
  max-width: 494px;
  display: flex;
  margin-right: 32px;
  justify-content: flex-end;
  @media (max-width: 1188px) {
    width: 100%;
    max-width: 100vw;
    margin-right: 0;
    margin-top: 24px;
  }
`;

const Wrap = styled.div`
  height: 100%;
  display: inline-flex;
  outline: 8px solid black;
  border-radius: 500px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-direction: column;
  svg {
    animation-name: turn;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  svg:first-child {
    margin-top: -40%;
  }
  svg:last-child {
    margin-bottom: -40%;
  }
  @media (max-width: 1188px) {
    flex-direction: row;
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    svg:first-child {
      margin-left: -20%;
      width: 100%;
      height: 100%;
      margin-top: 0;
    }
    svg:last-child {
      margin-right: -20%;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
    }
  }
  @media (max-width: 900px) {
    flex-direction: row;
    display: flex;
    width: 100%;
    svg:first-child {
      margin-left: -30%;
      width: 100%;
      height: 100%;
      margin-top: 0;
    }
    svg:last-child {
      margin-right: -30%;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
    }
  }
`;

const WorkItemTitle = styled.div`
  font-size: 48px;
  line-height: 48px;
  font-family: "Nichrome", sans-serif;
  color: black;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const WorkItemClient = styled.div`
  color: black;
  font-size: 48px;
  line-height: 48px;
  font-family: "Nichrome", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-family: "Object Sans Thin", sans-serif;
`;

const Header = styled.div`
  overflow: hidden;
  width: 100%;
  height: 700px;
  display: flex;
  background: var(--orange);
  font-family: "Nichrome Black", sans-serif;
  border-bottom: 2px solid black;

  ${Logo} {
    max-width: 763px;
    font-size: 80px;
    line-height: 80px;
    color: var(--text);
  }

  @media (max-width: 1188px) {
    flex-direction: column;
    height: auto;
  }
`;

const Button = styled.button`
  border-radius: 200px;
  padding: 8px 32px;
  background: transparent;
  border: 1px solid black;
  font-size: 30px;
  font-family: "Object Sans Thin", sans-serif;
  @media (max-width: 600px) {
    font-size: 20px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Heading = styled.div`
  font-family: "Nichrome", sans-serif;
  font-size: 80px;
  line-height: 80px;
  width: 100%;
  max-width: 750px;
  @media (max-width: 780px) {
    font-size: 40px;
    line-height: 48px;
    max-width: 100%;
  }
`;

const LatestWork = styled.div`
  width: 100%;
  background: var(--blue);
`;

const Title = styled.div`
  padding: 16px 12px;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: "Nichrome", sans-serif;
    font-size: 64px;
    line-height: 80px;
    @media (max-width: 600px) {
      font-size: 32px !important;
      line-height: 32px !important;
    }
  }
`;

const WorkItem = styled.a`
  width: 100%;
  min-height: 383px;
  border-right: 2px solid black;
  padding: 0 64px 16px 16px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-decoration: none;
  box-sizing: border-box;
  &:nth-child(3n + 3) {
    border-right: none;
  }
  &:hover {
    cursor: pointer;
    background: var(--darker-blue);
  }
  @media (max-width: 1188px) {
    border-right: none;
    border-bottom: 2px solid black;
    height: auto;
    padding: 24px 16px;
    min-height: auto;
  }
`;

const WorkWrap = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 2px solid black;
  ${WorkItem}:last-child {
    border: none;
  }

  @media (max-width: 1188px) {
    flex-direction: column;
  }
`;

const LatestUpdates = styled.div`
  background: var(--red);
`;

const Update = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px solid black;
  padding: 16px 0;
  &:last-child {
    border-bottom: none;
  }
`;

const UpdateTitle = styled.div`
  font-size: 48px;
  line-height: 48px;
  font-family: "Nichrome", sans-serif;
  white-space: nowrap;
  display: inline;
  margin-right: 50vw;
  @media (max-width: 600px) {
    font-size: 32px !important;
    line-height: 32px !important;
  }
`;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickerMoving: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.selected) {
      this.props.ReactGA.pageview("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected === false && this.props.selected === true) {
      window.scrollTo(0, 0);
      this.props.ReactGA.pageview("/");
    }
  }

  render() {
    if (this.props.selected) {
      return (
        <Wrapper>
          <Header>
            <HeaderLeft>
              <Logo>editaudio</Logo>
              <Heading>{this.props.homeContent.fields.headline}</Heading>
            </HeaderLeft>
            <HeaderRight>
              <Wrap>
                <svg
                  width="493"
                  height="493"
                  viewBox="0 0 493 493"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="249.787" cy="249.787" r="32.8667" fill="black" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M116.359 145.017C111.548 149.701 106.214 155.957 101.65 163.862C97.086 171.767 94.3356 179.514 92.6849 186.023L176.572 219.694C177.947 216.381 179.535 213.015 181.347 209.875C183.16 206.735 185.281 203.677 187.463 200.83L116.359 145.017ZM64.5001 194.212C63.9093 199.429 67.1276 204.097 72 206.052L178.771 248.909C186.379 251.963 194.929 247.381 197.366 239.554C199.134 233.875 201.428 227.681 204.118 223.022C206.808 218.362 211.025 213.279 215.059 208.908C220.619 202.884 220.312 193.189 213.864 188.127L123.363 117.089C119.233 113.847 113.582 113.394 109.359 116.514C101.547 122.287 88.8532 133.44 78.8792 150.715C68.9052 167.991 65.5931 184.56 64.5001 194.212Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M226.082 417.587C232.281 419.29 240.275 420.693 249.786 420.693C259.297 420.693 267.291 419.29 273.491 417.587L260.677 327.893C257.121 328.359 253.412 328.667 249.786 328.667C246.161 328.667 242.452 328.359 238.895 327.893L226.082 417.587ZM295.064 437.701C299.672 435.606 301.917 430.65 301.201 425.638L284.879 311.38C283.719 303.265 275.476 298.151 267.479 299.954C261.677 301.263 255.167 302.373 249.786 302.373C244.406 302.373 237.895 301.263 232.093 299.954C224.096 298.151 215.853 303.265 214.694 311.38L198.371 425.638C197.655 430.65 199.9 435.606 204.508 437.701C213.039 441.58 228.709 446.987 249.786 446.987C270.863 446.987 286.534 441.58 295.064 437.701Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M406.079 186.133C404.369 179.178 401.651 171.242 397.39 163.861C393.129 156.481 387.615 150.159 382.447 145.2L311.576 200.83C313.758 203.677 315.879 206.735 317.692 209.875C319.505 213.015 321.093 216.38 322.467 219.694L406.079 186.133ZM389.907 116.979C385.569 113.64 379.631 113.985 375.324 117.365L285.176 188.127C278.727 193.188 278.42 202.884 283.98 208.908C288.014 213.279 292.231 218.362 294.921 223.021C297.612 227.681 299.905 233.874 301.673 239.554C304.11 247.381 312.66 251.962 320.268 248.909L426.624 206.219C431.705 204.18 434.972 199.209 434.25 193.783C432.906 183.691 429.377 166.679 420.16 150.715C410.943 134.75 397.975 123.189 389.907 116.979Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M246.5 466.707C368.117 466.707 466.707 368.117 466.707 246.5C466.707 124.883 368.117 26.2933 246.5 26.2933C124.883 26.2933 26.2933 124.883 26.2933 246.5C26.2933 368.117 124.883 466.707 246.5 466.707ZM246.5 493C382.638 493 493 382.638 493 246.5C493 110.362 382.638 0 246.5 0C110.362 0 0 110.362 0 246.5C0 382.638 110.362 493 246.5 493Z"
                    fill="black"
                  />
                </svg>
                <svg
                  width="493"
                  height="493"
                  viewBox="0 0 493 493"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="249.787" cy="249.787" r="32.8667" fill="black" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M116.359 145.017C111.548 149.701 106.214 155.957 101.65 163.862C97.086 171.767 94.3356 179.514 92.6849 186.023L176.572 219.694C177.947 216.381 179.535 213.015 181.347 209.875C183.16 206.735 185.281 203.677 187.463 200.83L116.359 145.017ZM64.5001 194.212C63.9093 199.429 67.1276 204.097 72 206.052L178.771 248.909C186.379 251.963 194.929 247.381 197.366 239.554C199.134 233.875 201.428 227.681 204.118 223.022C206.808 218.362 211.025 213.279 215.059 208.908C220.619 202.884 220.312 193.189 213.864 188.127L123.363 117.089C119.233 113.847 113.582 113.394 109.359 116.514C101.547 122.287 88.8532 133.44 78.8792 150.715C68.9052 167.991 65.5931 184.56 64.5001 194.212Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M226.082 417.587C232.281 419.29 240.275 420.693 249.786 420.693C259.297 420.693 267.291 419.29 273.491 417.587L260.677 327.893C257.121 328.359 253.412 328.667 249.786 328.667C246.161 328.667 242.452 328.359 238.895 327.893L226.082 417.587ZM295.064 437.701C299.672 435.606 301.917 430.65 301.201 425.638L284.879 311.38C283.719 303.265 275.476 298.151 267.479 299.954C261.677 301.263 255.167 302.373 249.786 302.373C244.406 302.373 237.895 301.263 232.093 299.954C224.096 298.151 215.853 303.265 214.694 311.38L198.371 425.638C197.655 430.65 199.9 435.606 204.508 437.701C213.039 441.58 228.709 446.987 249.786 446.987C270.863 446.987 286.534 441.58 295.064 437.701Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M406.079 186.133C404.369 179.178 401.651 171.242 397.39 163.861C393.129 156.481 387.615 150.159 382.447 145.2L311.576 200.83C313.758 203.677 315.879 206.735 317.692 209.875C319.505 213.015 321.093 216.38 322.467 219.694L406.079 186.133ZM389.907 116.979C385.569 113.64 379.631 113.985 375.324 117.365L285.176 188.127C278.727 193.188 278.42 202.884 283.98 208.908C288.014 213.279 292.231 218.362 294.921 223.021C297.612 227.681 299.905 233.874 301.673 239.554C304.11 247.381 312.66 251.962 320.268 248.909L426.624 206.219C431.705 204.18 434.972 199.209 434.25 193.783C432.906 183.691 429.377 166.679 420.16 150.715C410.943 134.75 397.975 123.189 389.907 116.979Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M246.5 466.707C368.117 466.707 466.707 368.117 466.707 246.5C466.707 124.883 368.117 26.2933 246.5 26.2933C124.883 26.2933 26.2933 124.883 26.2933 246.5C26.2933 368.117 124.883 466.707 246.5 466.707ZM246.5 493C382.638 493 493 382.638 493 246.5C493 110.362 382.638 0 246.5 0C110.362 0 0 110.362 0 246.5C0 382.638 110.362 493 246.5 493Z"
                    fill="black"
                  />
                </svg>
              </Wrap>
            </HeaderRight>
          </Header>
          <LatestWork>
            <Title>
              <span>Featured Work</span>
              <Link to="/work">
                <Button>More</Button>
              </Link>
            </Title>
            <WorkWrap>
              {this.props.homeContent.fields.featuredEpisodes.map((episode) => (
                <WorkItem
                  key={episode.fields.title}
                  href={episode.fields.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WorkItemTitle>{episode.fields.title}</WorkItemTitle>
                  <WorkItemClient>{episode.fields.client}</WorkItemClient>
                </WorkItem>
              ))}
            </WorkWrap>
          </LatestWork>
          {this.props.homeContent.fields.updatesOn && (
            <LatestUpdates>
              <Title>
                <span>Latest Updates</span>
              </Title>
              <Update
                onMouseEnter={() => this.setState({ tickerMoving: false })}
                onMouseLeave={() => this.setState({ tickerMoving: true })}
              >
                <Ticker mode="smooth" speed={20} move={this.state.tickerMoving}>
                  {() => (
                    <div style={{ display: "inline" }}>
                      {this.props.updates.map((update) => (
                        <a key={update.fields.title} href={update.fields.url}>
                          <UpdateTitle>{update.fields.title}</UpdateTitle>
                        </a>
                      ))}
                    </div>
                  )}
                </Ticker>
              </Update>
            </LatestUpdates>
          )}
        </Wrapper>
      );
    } else {
      return (
        <Link to="/" style={{ textDecoration: "none" }}>
          <Wrapper className="collapsed" collapsed>
            <Logo sideways>editaudio</Logo>
          </Wrapper>
        </Link>
      );
    }
  }
}

export default Home;
