import React, { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  min-height: -webkit-fill-available;
  position: fixed;
  left: 0px;
  top: 0px;
  background: var(--orange);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  font-family: "Nichrome Black", sans-serif;
  font-size: 80px;
  line-height: 80px;
  white-space: nowrap;
  color: var(--text);
  writing-mode: ${(props) =>
    props.sideways ? "vertical-rl" : "horizontal-tb"};

  @media (max-width: 600px) {
    font-size: 32px !important;
    line-height: 32px !important;
  }
`;

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    return (
      <Wrapper>
        <Logo>loading</Logo>
      </Wrapper>
    );
  }
}

export default Loading;
