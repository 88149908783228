import React, { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 48px;
  background: var(--red);
  position: fixed;
  bottom: -50px;
  right: 24px;
  display: ${(props) => (props.firstRun ? "none" : "flex")};
  align-items: center;
  padding: 0px 24px;
  border-radius: 200px;
  border: 2px black solid;
  font-size: 20px;
  line-height: 24px;
  justify-content: center;
  box-sizing: border-box;
  font-family: "Object Sans Thin", sans-serif;
  animation-name: ${(props) => (props.active ? "popIn" : "popOut")};
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  @media (max-width: 600px) {
    width: calc(100vw - 32px);
    right: 16px;
    font-size: 16px;
    line-height: 20px;
  }
`;

class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstRun: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active === false && this.props.active === true) {
      this.setState({
        firstRun: false,
      });
    }
  }

  render() {
    return (
      <Wrapper active={this.props.active} firstRun={this.state.firstRun}>
        {this.props.text}
      </Wrapper>
    );
  }
}

export default Alert;
