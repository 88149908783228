import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  background: var(--green);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: ${(props) => (props.collapsed ? "24px" : "none")};
  @media (max-width: 1188px) {
    display: ${(props) => (props.collapsed ? "none" : "flex")};
    padding-top: 100px;
  }
  &:hover {
    cursor: ${(props) => (props.collapsed ? "pointer" : "default")};
    background: ${(props) =>
      props.collapsed ? "var(--darker-green)" : "var(--green)"};
  }
`;

const Content = styled.div`
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.collapsed ? "0px" : "80px 0")};
  @media (max-width: 1188px) {
    padding: 0px 16px;
    width: 100%;
  }
`;

const Heading = styled.div`
  font-family: "Nichrome", sans-serif;
  color: var(--text);
  font-size: ${(props) => (props.sideways ? "40px" : "80px")};
  line-height: ${(props) => (props.sideways ? "40px" : "80px")};
  writing-mode: ${(props) =>
    props.sideways ? "vertical-rl" : "horizontal-tb"};
  @media (max-width: 780px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const Heading2 = styled.div`
  font-family: "Nichrome", sans-serif;
  font-size: 64px;
  line-height: 80px;
  margin-top: 64px;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const Introduction = styled.div`
  line-height: 48px;
  font-size: 40px;
  font-family: "Object Sans Thin", sans-serif;
  margin-top: 24px;
  @media (max-width: 780px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const SectionText = styled.div`
  font-size: 32px;
  line-height: 40px;
  font-family: "Object Sans Thin", sans-serif;
  margin-top: 24px;
  @media (max-width: 780px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const Team = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Img = styled.div`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.bg});
`;

const TeamMember = styled.div`
  position: relative;
  width: 100%;
  &:after {
    padding-top: 100%;
    display: block;
    content: "";
  }
  ${Img} {
    width: 100%;
    border-radius: 18px;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  @media (max-width: 600px) {
    ${Img} {
      height: calc(100vw - 32px);
    }
  }
`;

const MemberWrap = styled.div`
  font-family: "Nichrome", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  box-sizing: border-box;
  margin-bottom: 16px;
  min-width: calc(1000px / 3);
  max-width: calc(1000px / 3);
  flex: 1;
  @media (max-width: 1188px) {
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
  }
  @media (max-width: 700px) {
    min-width: calc(100% / 2);
    max-width: calc(100% / 2);
  }
  @media (max-width: 600px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    margin-bottom: 24px;
    padding-right: 0;
  }
`;

const Name = styled.div`
  margin-top: 8px;
  font-size: 48px;
  line-height: 48px;
  font-family: "Nichrome", sans-serif;
  @media (max-width: 780px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

const Role = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-family: "Object Sans Thin", sans-serif;
`;

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.selected) {
      this.props.ReactGA.pageview("/about");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected === false && this.props.selected === true) {
      window.scrollTo(0, 0);
      this.props.ReactGA.pageview("/about");
    }
  }

  render() {
    if (this.props.selected) {
      return (
        <Wrapper>
          <Content>
            <Heading>About Us</Heading>
            <Introduction>
              {this.props.aboutContent.fields.aboutUs}
            </Introduction>
            <Heading2>What We Do</Heading2>
            <SectionText>{this.props.aboutContent.fields.whatWeDo}</SectionText>
            <Heading2>How We Do It</Heading2>
            <SectionText>
              {this.props.aboutContent.fields.howWeDoIt}
            </SectionText>
            <Heading2>Why We Do It</Heading2>
            <SectionText>
              {this.props.aboutContent.fields.whyWeDoIt}
            </SectionText>
            <Heading2>Our Team</Heading2>
            <Team>
              {this.props.team.map((item) => (
                <MemberWrap key={item.fields.name}>
                  <TeamMember>
                    <Img bg={item.fields.image.fields.file.url} />
                  </TeamMember>
                  <Name>{item.fields.name}</Name>
                  <Role>{item.fields.role}</Role>
                </MemberWrap>
              ))}
            </Team>
          </Content>
        </Wrapper>
      );
    } else {
      return (
        <Link to="/about" style={{ textDecoration: "none" }}>
          <Wrapper className="collapsed" collapsed>
            <Heading sideways>About Us</Heading>
          </Wrapper>
        </Link>
      );
    }
  }
}

export default About;
