import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Original from "../images/original.svg";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: var(--blue);
  padding-top: ${(props) => (props.collapsed ? "24px" : "40px")};
  display: ${(props) => (props.collapsed ? "flex" : "block")};
  justify-content: center;
  border-right: 2px solid var(--black);
  @media (max-width: 1188px) {
    display: ${(props) => (props.collapsed ? "none" : "block")};
    padding-top: 100px;
    border-right: none;
  }
  &:hover {
    cursor: ${(props) => (props.collapsed ? "pointer" : "default")};
    background: ${(props) =>
      props.collapsed ? "var(--darker-blue)" : "var(--blue)"};
  }
`;

const Header = styled.div`
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.collapsed ? "0px" : "40px 16px;")};
  @media (max-width: 1188px) {
    padding: 0 16px 16px 16px;
  }
`;

const Heading = styled.div`
  font-family: "Nichrome", sans-serif;
  color: var(--text);
  font-size: ${(props) => (props.sideways ? "40px" : "80px")};
  line-height: ${(props) => (props.sideways ? "40px" : "80px")};
  writing-mode: ${(props) =>
    props.sideways ? "vertical-rl" : "horizontal-tb"};
  @media (max-width: 780px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const Introduction = styled.div`
  line-height: 48px;
  font-size: 40px;
  font-family: "Object Sans Thin", sans-serif;
  margin-top: 24px;
  @media (max-width: 780px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const WorkItem = styled(Link)`
  box-sizing: border-box;
  height: 340px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: var(--black);
  text-decoration: none;
  &:nth-child(3n + 3) {
    border-right: none;
  }
  &:hover {
    cursor: pointer;
    background: var(--darker-blue);
  }
  @media (max-width: 950px) {
    border-right: none;
  }
`;

const WorkWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc((100vw - 146px) / 3), 1fr)
  );
  grid-auto-rows: auto;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }
`;

const WorkItemTitle = styled.div`
  font-size: 48px;
  line-height: 48px;
  font-family: "Nichrome", sans-serif;
  @media (max-width: 780px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const WorkItemDescription = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-family: "Object Sans Thin", sans-serif;
`;

const WorkItemCover = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 18px;
`;

const WorkItemTop = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`;
const WorkItemBottom = styled.div``;

const OriginalBadge = styled.div`
  width: 80px;
  height: 80px;
  transform: rotateZ(-15deg);
  img {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

class Work extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.selected) {
      this.props.ReactGA.pageview("/work");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected === false && this.props.selected === true) {
      window.scrollTo(0, 0);
      this.props.ReactGA.pageview("/work");
    }
  }

  render() {
    if (this.props.selected) {
      return (
        <Wrapper>
          <Header>
            <Heading>Our Work</Heading>
            <Introduction>{this.props.workPage.fields.intro}</Introduction>
          </Header>
          <WorkWrap>
            {this.props.workItems.map((item) => (
              <WorkItem key={item.fields.name} to={`/work/${item.fields.slug}`}>
                <WorkItemTop>
                  {item.fields.coverArt.fields.file.url && (
                    <WorkItemCover
                      src={`${item.fields.coverArt.fields.file.url}?fm=jpg&fl=progressive`}
                    />
                  )}
                  {item.fields.original == true && (
                    <OriginalBadge>
                      <img src={Original} />
                    </OriginalBadge>
                  )}
                </WorkItemTop>
                <WorkItemBottom>
                  <WorkItemTitle>{item.fields.name}</WorkItemTitle>
                  {item.fields.shortDescription && (
                    <WorkItemDescription>
                      {item.fields.shortDescription}
                    </WorkItemDescription>
                  )}
                </WorkItemBottom>
              </WorkItem>
            ))}
          </WorkWrap>
        </Wrapper>
      );
    } else {
      return (
        <Link to="/work" style={{ textDecoration: "none" }}>
          <Wrapper className="collapsed" collapsed>
            <Heading sideways>Our Work</Heading>
          </Wrapper>
        </Link>
      );
    }
  }
}

export default Work;
